import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import {
  makeStyles,
  Typography,
  Container,
  TextField,
  Button,
  Grid,
} from "@material-ui/core"
import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
  submitBtn: {
    marginTop: "9px",
    marginLeft: "16px",
    marginBottom: "16px",
  },
  headGrid: {
    height: "12vh",
    background: "#000",
    width: "100%",
  },
  paragraph: {
    fontSize: "2vh",
  },
}))

const Nalog = props => {
  const classes = useStyles()
  // const baseUri = 'https://localhost:5001/api';
  const baseUri = props.data.site.siteMetadata.orderApi
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    const qr = params.get("qr")
    const request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
    fetch(baseUri + "/qr/" + qr, request)
      .then(r =>
        r.json().then(o => {
          setData(o)
        })
      )
      .catch(r => console.log(r))
      .finally(() => setLoading(false))
  }, [baseUri])

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target

    const formData = new FormData(form)
    const params = new URLSearchParams(document.location.search)
    const qr = params.get("qr")

    setSubmitting(true)
    fetch(baseUri + "/qr/" + qr, { method: "POST", body: formData })
      .then(r => {
        setSubmitted(true)
      })
      .catch(r => console.log(r))
      .finally(() => setSubmitting(false))
  }

  if (loading)
    return (
      <Layout location={props.location} title={"Tražim nalog"}>
        <Grid className={classes.headGrid}></Grid>
        <Container maxWidth="xl">
          <Typography
            variant="h4"
            component="h1"
            align="center"
            color="primary"
            gutterBottom
          >
            Učitavam nalog
          </Typography>
        </Container>
      </Layout>
    )

  if (!data)
    return (
      <Layout location={props.location} title={"Nepoznat nalog"}>
        <Grid className={classes.headGrid}></Grid>
        <Container maxWidth="xl">
          <Typography
            variant="h4"
            component="h1"
            align="center"
            color="primary"
            gutterBottom
          >
            Nalog nije pronađen
          </Typography>
          <hr />
          <p className={classes.paragraph}>
            Provjerite jeste li upisali ispravan URL.
          </p>
          <p className={classes.paragraph}>
            Ako je upisan ispravan URL, a i dalje se ne prikazuje status naloga,
            kontaktirajte nas.
          </p>
        </Container>
      </Layout>
    )

  return (
    <Layout location={props.location} title={`Nalog ${props.params.order}`}>
      <Grid className={classes.headGrid}></Grid>
      <Container maxWidth="xl">
        <Typography
          variant="h3"
          component="h2"
          align="center"
          color="primary"
          gutterBottom
        >
          Radni nalog br.: {data.id}
        </Typography>
        <p className={classes.paragraph}>Pozdrav {data.contact},</p>
        {data.returned ? (
          <>
            <p className={classes.paragraph}>
              Predmet {data.subject} je preuzet sa servisa dana: {data.returned}{" "}
              sati.
            </p>
            <p className={classes.paragraph}>
              Odrađeni su radovi: {data.description}
              {data.completed && <>s danom {data.completed} sati</>}.
            </p>
            {data.realPrice && (
              <p className={classes.paragraph}>
                Plaćen iznos radova: {data.realPrice} €
              </p>
            )}
            <p className={classes.paragraph}>Hvala Vam na podršci.</p>
            <p className={classes.paragraph}>
              Želimo Vam ugodnu vožnju i puno sretnih kilometara!
            </p>
          </>
        ) : data.completed ? (
          <>
            <p className={classes.paragraph}>
              Dogovoreni radovi {data.description} na predmetu {data.subject} su
              uspješno završeni {data.completed} sati.
            </p>
            {data.realPrice && (
              <p className={classes.paragraph}>
                Konačan iznos radova: {data.realPrice} €
              </p>
            )}
            <p className={classes.paragraph}>Možete doći po bicikl.</p>
            <p className={classes.paragraph}>
              Zahvaljujemo Vam na ukazanom povjerenju.
            </p>
          </>
        ) : data.arrived ? (
          <>
            <p className={classes.paragraph}>
              Predmet {data.subject} je dostavljen {data.arrived} sati te je u
              procesu servisiranja.
            </p>
            <p className={classes.paragraph}>
              Dogovoreni radovi: {data.description}
            </p>
            {data.estimatedPrice && (
              <p className={classes.paragraph}>
                Okvirna procjena iznosa radova: ~ {data.estimatedPrice} €
              </p>
            )}
            <p className={classes.paragraph}>
              Provjerite status naloga ponovo kasnije.
            </p>
          </>
        ) : (
          <>
            <p className={classes.paragraph}>
              Vaš predmet {data.subject} još nije preuzet na servis. Dogovoreno
              vrijeme i datum preuzimanja je najkasnije do: {data.arrival} sati.
            </p>
            <p className={classes.paragraph}>
              Dogovoreni radovi koji će se obavljati: {data.description}
            </p>
            {data.estimatedPrice && (
              <p className={classes.paragraph}>
                Okvirna procjena iznosa radova: ~ {data.estimatedPrice} €
              </p>
            )}
            <p className={classes.paragraph}>
              Hvala Vam na ukazanom povjerenju i vidimo se uskoro!
            </p>
          </>
        )}
        <hr />
        <p className={classes.paragraph}>
          Primite obavijest o završetku radova na svoj email.
        </p>
        {submitted ? (
          <p className={classes.paragraph}>Spremljeno</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              name="email"
              label="Email adresa"
              size="small"
              defaultValue={data.email}
            />
            <Button
              type="submit"
              variant="contained"
              className={classes.submitBtn}
              disabled={submitting}
            >
              Spremi
            </Button>
          </form>
        )}
      </Container>
    </Layout>
  )
}

export default Nalog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        orderApi
      }
    }
  }
`
